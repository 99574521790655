@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1920' height='1080' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg clip-path='url(%26quot%3b%23SvgjsClipPath1246%26quot%3b)' fill='none'%3e%3ccircle r='72.265' cx='992.38' cy='598.22' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='95.17' cx='585.96' cy='167.63' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='49.4' cx='1897.12' cy='380.1' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='36.27' cx='1615.27' cy='905.51' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='97.89' cx='392.44' cy='432.08' fill='rgba(240%2c 173%2c 53%2c 0.5)'%3e%3c/circle%3e%3ccircle r='39.68' cx='394.16' cy='1015.22' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='51.805' cx='1313.78' cy='43.96' fill='rgba(240%2c 173%2c 53%2c 0.5)'%3e%3c/circle%3e%3ccircle r='102.325' cx='1122.49' cy='1076.41' fill='rgba(240%2c 173%2c 53%2c 0.5)'%3e%3c/circle%3e%3ccircle r='91.24' cx='611.37' cy='928.59' fill='rgba(240%2c 173%2c 53%2c 0.5)'%3e%3c/circle%3e%3ccircle r='28.22' cx='641.65' cy='756.48' fill='rgba(51%2c 135%2c 140%2c 0.5)'%3e%3c/circle%3e%3ccircle r='31.84' cx='1431.35' cy='535.07' fill='rgba(240%2c 173%2c 53%2c 0.5)'%3e%3c/circle%3e%3c/g%3e%3cdefs%3e%3cclipPath id='SvgjsClipPath1246'%3e%3crect width='1920' height='1080' x='0' y='0'%3e%3c/rect%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
